.grid-row {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: repeat(auto-fill, var(--grid-column-width));
  padding-left: var(--grid-gutter);
  padding-right: var(--grid-gutter);
}

.grid-gutter {
  padding-left: var(--grid-gutter);
  padding-right: var(--grid-gutter);
}

.max-content-width {
  max-width: $maxContentWidth;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid-row,
.grid-gutter {
  .grid-row {
    padding-left: 0;
    padding-right: 0;
  }
}

// todo use map-get from shared-variables.json..
//@include grid-system(#{map_get($grid, MIN_0, columnCount)})
@include grid-system(6);

@include respond-to(MIN_768) {
  @include grid-system(8, 'grid-col-m-');
}

@include respond-to(MIN_1024) {
  @include grid-system(12, 'grid-col-l-');
  //  @include grid-system(#{map_get($grid, MIN_1024, columnCount)}, "grid-col-l-");
}
