@import "util.scss";
@import "layout/grid";

:root {
  --font: 'PorscheNext', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;
  --font-xs: 0.75rem; /* 12px */
  --font-sm: 1rem; /* 16px */
  --font-md: 1.5rem; /* 24px */
  --font-lg: 2.25rem; /* 36px */
  --font-xl: 3.25rem; /* 52px */
  --font-xxl: 5rem; /* 80px */
  --weight-thin: 400;
  --weight-regular: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --line-height: calc(6px + 2.125ex);
  --space-4: 0.25rem;
  --space-8: 0.5rem;
  --space-16: 1rem;
  --space-20: 1.25rem;
  --space-24: 1.5rem;
  --space-28: 1.75rem;
  --space-32: 2rem;
  --space-40: 2.5rem;
  --space-48: 3rem;
  --space-56: 3.5rem;
  --space-60: 3.75rem;
  --space-64: 4rem;
  --space-72: 4.5rem;
  --space-80: 5rem;
  --space-120: 7.5rem;
  --space-xs: var(--space-4);
  --space-sm: var(--space-8);
  --space-md: var(--space-16);
  --space-lg: var(--space-32);
  --space-xl: var(--space-48);
  --space-xxl: var(--space-80);
  --color-white: #fff;
  --color-black: #000;
  --color-brand: #d5001c;
  --color-primary: var(--color-brand);
  --color-neutral-10: #262B2F;
  --color-neutral-20: #323639;
  --color-neutral-30: #626669;
  --color-neutral-40: #96989A;
  --color-neutral-50: #E3E4E5;
  --color-neutral-60: #F6F6F6;
  --color-neutral-10-dark:#0e1418;
  --color-neutral-20-dark:#262b2e;
  --color-neutral-30-dark:#4a4e51;
  --color-neutral-40-dark:#7c7f81;
  --color-neutral-50-dark:#b0b1b2;
  --color-neutral-60-dark:#e3e4e5;
  --color-success: #01ba1d;
  --color-success-soft: #bfeec6;
  --color-warning: #ff9b00;
  --color-warning-soft: #ffe6bf;
  --color-error: #fc1717;
  --color-error-soft: #fec5c5;
  --color-neutral-blue: #2193ff;
  --color-neutral-blue-soft: #c7e4ff;
  --color-hover: '#ff0223';
  --color-active: '#ff0223';
  --color-focus: currentColor;
  --color-disabled: '#7c7f81';
  --header-height: 96px; // 40 + 2 * 28
  --input-height: 42px;
  @include respond-to(MIN_768) {
    --input-height: 42px;
    --header-height: 106px; // 50 + 2 * 28
  }
  @include respond-to(MIN_1024) {
    --input-height: 56px;
    --header-height: 122px; // 60 + 2 * 32
  }
  @include setGridProperties();
}

.consent-wall-overlay {
  color: black;
}

html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font: var(--font);
  font-size: var(--font-sm);
  font-weight: var(--weight-regular);
  line-height: var(--line-height);
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: none;

  &:focus-within {
    scroll-behavior: smooth;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  font-family: var(--font);
  font-weight: normal;
  margin: 0;
  word-wrap: break-word;
  background: #000;
  color: var(--color-white, #fff);
  overscroll-behavior-y: none;
}

.heading-01 {
  @include heading-01();
}

.heading-02 {
  @include heading-02();
}

.heading-03 {
  @include heading-03();
}

.heading-04 {
  @include heading-04();
}

.heading-05 {
  @include heading-05();
}

.body-large {
  @include body-lg();
}

.grey-40 {
  color: var(--color-neutral-40);
}

.grey-50 {
  color: var(--color-neutral-50);
}

/* CardboardBox sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  line-height: var(--line-height);
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
blockquote,
dl,
dd,
hr {
  margin: 0;
  line-height: var(--line-height);
}

/* Remove list styles on ul, ol elements, which suggests default styling will be removed */
ul,ol {
  list-style: none;
  padding: 0;
  margin: 0;
}


/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: var(--color-white);
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  all: unset;
  cursor: pointer;
}

hr {
  border-bottom: 0;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

[role="button"] {
  cursor: pointer;
}

input, textarea, button, a {
   &:focus {
     outline: none
   }
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
    width: 0;
    height: 0;
  }
}
