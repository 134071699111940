@font-face {
  font-family: "PorscheNext";
  src: url('/assets/font/porsche-next/PorscheNext-Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PorscheNext";
  src: url('/assets/font/porsche-next/PorscheNext-SemiBold.otf') format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PorscheNext";
  src: url('/assets/font/porsche-next/PorscheNext-Bold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PorscheNext";
  src: url('/assets/font/porsche-next/PorscheNext-Thin.otf') format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// Heading 01
@mixin heading-01 {
  font-weight: var(--weight-semibold);
  font-size: 2.5rem;

  @media (min-width: 768px) and (max-width: 1440px) {
    font-size: 3.25rem
  }
  @media (min-width: 1440px) {
    font-size: 5rem
  }
}

// Heading 02
@mixin heading-02 {
  font-weight: var(--weight-semibold);
  font-size: 2rem;

  @media (min-width: 768px) and (max-width: 1440px) {
    font-size: 2.5rem
  }
  @media (min-width: 1440px) {
    font-size: 4rem
  }
}

// Heading 03
@mixin heading-03 {
  font-weight: var(--weight-semibold);
  font-size: 1.625rem;

  @media (min-width: 768px) and (max-width: 1440px) {
    font-size: 2rem
  }
  @media (min-width: 1440px) {
    font-size: 2.5rem
  }
}

// Heading 04
@mixin heading-04 {
  font-weight: var(--weight-semibold);
  font-size: 1.375rem;

  @media (min-width: 768px) and (max-width: 1440px) {
    font-size: 1.5rem
  }
  @media (min-width: 1440px) {
    font-size: 2rem
  }
}

// Heading 05
@mixin heading-05 {
  font-weight: var(--weight-semibold);
  font-size: 1.125rem;

  @media (min-width: 768px) and (max-width: 1440px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1440px) {
    font-size: 1.5rem
  }
}

// Body XS
@mixin body-xs {
  font-size: var(--font-xs);
}

// Body small (Body standard);
@mixin body-sm {
  font-size: var(--font-sm);
  line-height: 1.5;
}

// Body medium;
@mixin body-md {
  font-size: 1.125rem;

  @media (min-width: 1440px) {
    font-size: 1.25rem
  }
}

// Body large
@mixin body-lg {
  font-size: 1.25rem;

  @media (min-width: 1440px) {
    font-size: 1.5rem
  }
}

// Body XS
@mixin body-xl {
  font-size: var(--font-xl);
}
