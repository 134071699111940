// Sizes
$minimumFluidTypeViewportWidth: 375px;
$minimumTabletFluidTypeViewportWidth: 768px;
$maximumFluidTypeViewportWidth: 1920px;

$maxContentWidth: 2560px;

$zLayout: background, canvas, overlay, cutScene, loaderOverlay, menu, header, contactOverlay;

$baseFontSize: 16;

@import "src/data/shared-variable/shared-variables.json";